@import "fonts";
@import "colors";

@import "~bootstrap/scss/bootstrap";
@import "~multi.js/dist/multi.min.css";
@import "~material-icons/iconfont/material-icons";
//@import "evals";

body {
    font-family: 'Montserrat', sans-serif !important;
    color: var(--dark);
}

body.brain-user {
    padding-top: 56px;
}

.bg-primary {
    background-color: $primary;
}

.bg-light {
    background-color: #c3cad9!important;
}

.text-primary {
    color: $primary;
}

.alert-success {
    color: #045e2f;
    background-color: #cdf0de;
    border-color: #baead1;
}

.alert-danger {
    color: #741915;
    background-color: #f9d6d4;
    border-color: #f6c5c3;
}

.badge-success {
    color: #fff;
    background-color: #07b55b;
}

a.badge-success:focus,
a.badge-success:hover {
    color: #fff;
    background-color: #058442;
}

a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(7, 181, 91, 0.5);
}

a:link {
    text-decoration: none !important;
}

.btn-primary {
    background-color: $primary;
}

.badge-danger {
    color: #fff;
    background-color: #e03128;
}

.badge-warning {
    color: #212529;
    background-color: #ffb405;
}

a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #ba231b;
}

a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(224, 49, 40, 0.5);
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'NunitoSans-Bold', sans-serif !important;
}

strong,
.font-weight-bold {
    font-family: 'Montserrat-Bold', sans-serif !important;
}

.canceled {
    background-color: rgba($danger, .5) !important;
}

.finished {
    opacity: .5;
}

.pointer {
    cursor: pointer;
}

.handle {
    cursor: move;
}

.auth-logo {
    max-width: 180px;
}

.green-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 10px;
    background: #07b55b;
}

.dropdown-toggle:after {
    content: none !important;
}

.header {
    .flag {
        width: 25px;
    }

    .dropdown-menu {
        .flag {
            width: 20px;
        }
    }
}

.icon-lg {
    font-size: 32px;
    line-height: 32px;
}

.icon-xlg {
    font-size: 45px;
    line-height: 45px;
}

.btn-xs {
    padding: 0.2rem 0.6rem;
    font-size: 0.8rem;
    line-height: 1.2;
}

.logo {
    width: 80px;
}

.nunito {
    font-family: 'NunitoSans', sans-serif !important;
}

.nunito-bold {
    font-family: 'NunitoSans-Bold', sans-serif !important;
}

.text-lg {
    font-size: 1.2rem;
    line-height: 1.2rem;
}

.text-md {
    font-size: 0.95rem;
    line-height: 0.95rem;
}

.text-sm {
    font-size: 0.85rem;
}

.title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #dee2e6;

    .back-button {
        text-decoration: none;
        color: var(--secondary);
        margin-right: 1rem;
        display: flex;
        padding: 0;
    }
}

.mx-height-150px {
    height: 150px;
    max-height: 150px;
}

tr.disabled {
    opacity: .6;
}

/** Partner form **/
#partners-list {
    .image {
        width: 40px;
        height: 40px;
    }
}

#partner-form {
    #image {
        width: 140px;
        height: 140px;
    }
}

.form-inline {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    label {
        display: flex !important;
        margin-bottom: 0;
    }

    .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}

.navbar {
    padding: 0.5rem 1rem;

    .dropstart .dropdown-toggle:before
    {
        display: none !important;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.list-group-item-dark {
    color: #1b1b1b;
    background-color: #c6c6c6;
    padding: 0.75rem 1.25rem;

    &:hover {
        background-color: #b9b9b9;
    }
}

.table td {
    padding: 1rem;
}

.btn-primary {
    color: $white !important;

    &:hover {
        background-color: #d45539 !important;

    }
}

.btn-outline-primary {
    color: $primary;
    border-color: $primary;

    &:hover {
        color: $white;
        background-color: $primary;
    }
}